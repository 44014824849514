import React, { Component, createElement } from 'react';
import { renderToString } from 'react-dom/server';
import Modal from "./Component/Modal";
import { toPng, toBlob } from 'html-to-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateLeft, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import { faBurst as faBurstSolid } from '@fortawesome/sharp-solid-svg-icons'
import { faHandFist, faJetFighter, faBrain, faHeart, faMicrochip, faPaw, faHammer, faWater, faBurst } from '@fortawesome/pro-light-svg-icons'
import superHerosImg from './imgs/Naomi-BG2x.webp'
import startBg from './imgs/Curtains-BG2x.webp'
import startHeading from './imgs/Superhero-Identity2x.webp'
import rasterDotsTop from './imgs/Raster-Dots-Top2x.png'
import rasterDotsBottom from './imgs/Raster-Dots-Bottom2x.png'
import giveLoveImg from './imgs/Give-Love2x.png'
import shcLogo from './imgs/shcLogo.png'
import './App.scss';

class SuperHeroNames {
  constructor() {
    //List of colors
    this.colors = [
      { text: 'Metallic' },
      { text: 'Glittering' },
      { text: 'Golden' },
      { text: 'Silver' },
      { text: 'Rainbow' },
      { text: 'Iridescent' },
      { text: 'Glowing' },
      { text: 'Shimmering' },
      { text: 'Platinem' },
      { text: 'Mauve' },
      { text: 'Magenta' },
      { text: 'Iron' },
    ];

    //List of adjectives with associated tags
    this.adjectives = [
      { text:'amazing', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'ambiguous', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'amorphus', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'awesome', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'bionic', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text:'bold', superHeroPowers: [ 'strength', 'smart', 'kind' ], superHeroType: [ 'action', 'nature' ] },
      { text:'brilliant', superHeroPowers: [ 'smart', 'fly', 'kind', 'speed' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'colossal', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'cosmic', superHeroPowers: [ 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'daring', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'dazzling', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'dynamic', superHeroPowers: [ 'smart', 'tech', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'effervescent', superHeroPowers: [ 'fly', 'kind', 'speed' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'electric', superHeroPowers: [ 'tech', 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'elusive', superHeroPowers: [ 'smart', 'fly', 'speed' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'fantastic', superHeroPowers: [ 'smart', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature' ] },
      { text:'floating', superHeroPowers: [ 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'flying', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'galvanized', superHeroPowers: [ 'strength', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'gummy', superHeroPowers: [ 'strength', 'tech' ], superHeroType: [ 'animal', 'object' ] },
      { text:'human', superHeroPowers: [ 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object' ] },
      { text:'iconic', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'impenetrable', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'impressive', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'incredible', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'infinite', superHeroPowers: [ 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'invincible', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'iron', superHeroPowers: [ 'strength', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text:'ironic', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'magnificent', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'mega', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'metaphysical', superHeroPowers: [ 'smart', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'mighty', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'modest', superHeroPowers: [ 'strength', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'outstanding', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'powerful', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'remarkable', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'sensational', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'strong', superHeroPowers: [ 'strength', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'super', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'thunderous', superHeroPowers: [ 'strength', 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'unbeatable', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'unconvincing', superHeroPowers: [ 'strength', 'kind', 'speed' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'unforgettable', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'unstoppable', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'wheeled', superHeroPowers: [ 'kind', 'tech', 'smart' ], superHeroType: [ 'object' ] },
      { text:'wonderful', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'wonderous', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text:'fearless', superHeroPowers: [ 'strength', 'kind' ], superHeroType: ['animal', 'nature', 'action'] }
    ];

    //List of nouns with associated tags
    this.nouns = [
      { text: 'avenger', superHeroPowers: [ 'strength', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'enforcer', superHeroPowers: [ 'strength', 'smart', 'kind' ], superHeroType: [ 'action' ] },
      { text: 'hacker', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'heart hugger', superHeroPowers: [ 'kind' ], superHeroType: [ 'action' ] },
      { text: 'hugger', superHeroPowers: [ 'kind' ], superHeroType: [ 'action' ] },
      { text: 'jammer', superHeroPowers: [ 'strength' ], superHeroType: [ 'action' ] },
      { text: 'magician', superHeroPowers: [ 'smart', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'operator', superHeroPowers: [ 'smart', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'paragon', superHeroPowers: [ 'smart', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'peace patrol', superHeroPowers: [ 'kind', 'strength' ], superHeroType: [ 'action' ] },
      { text: 'rampage', superHeroPowers: [ 'strength', 'kind' ], superHeroType: [ 'action' ] },
      { text: 'randomizer', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'renegade', superHeroPowers: [ 'smart', 'strength', ], superHeroType: [ 'action' ] },
      { text: 'rescuer', superHeroPowers: [ 'strength', 'kind' ], superHeroType: [ 'action' ] },
      { text: 'revolution', superHeroPowers: [ 'smart', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'rocker', superHeroPowers: [ 'fly', 'smart', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'sentinel', superHeroPowers: [ 'strength', 'smart', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'sneezer', superHeroPowers: [ 'strength', 'fly' ], superHeroType: [ 'action' ] },
      { text: 'voyager', superHeroPowers: [ 'fly', 'tech', 'kind' ], superHeroType: [ 'action' ] },
      { text: 'ace', superHeroPowers: [ 'strength', 'fly', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'acrobat', superHeroPowers: [ 'fly', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'aesthete', superHeroPowers: [ 'kind', 'tech', 'smart' ], superHeroType: [ 'action' ] },
      { text: 'alchemist', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'astronaut', superHeroPowers: [ 'smart', 'tech', 'fly' ], superHeroType: [ 'action' ] },
      { text: 'bandit', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'braveheart', superHeroPowers: [ 'strength', 'smart', 'kind' ], superHeroType: [ 'action' ] },
      { text: 'champ', superHeroPowers: [ 'strength', 'smart', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'collector', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'crush', superHeroPowers: [ 'strength', 'smart', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'defender', superHeroPowers: [ 'kind' ], superHeroType: [ 'action' ] },
      { text: 'doc', superHeroPowers: [ 'kind', 'tech', 'smart' ], superHeroType: [ 'action' ] },
      { text: 'empathizer', superHeroPowers: [ 'kind' ], superHeroType: [ 'action' ] },
      { text: 'gameface', superHeroPowers: [ 'strength', 'smart' ], superHeroType: [ 'action' ] },
      { text: 'giant', superHeroPowers: [ 'strength', 'kind' ], superHeroType: [ 'action' ] },
      { text: 'guardian angel', superHeroPowers: [ 'kind' ], superHeroType: [ 'action' ] },
      { text: 'bear', superHeroPowers: [ 'strength', 'kind' ], superHeroType: [ 'animal' ] },
      { text: 'cobra', superHeroPowers: [ 'strength', 'smart' ], superHeroType: [ 'animal' ] },
      { text: 'dragon', superHeroPowers: [ 'strength', 'fly', 'smart' ], superHeroType: [ 'animal' ] },
      { text: 'duck', superHeroPowers: [ 'kind', 'fly' ], superHeroType: [ 'animal' ] },
      { text: 'eagle', superHeroPowers: [ 'strength', 'kind', 'tech', 'smart' ], superHeroType: [ 'animal' ] },
      { text: 'elephant', superHeroPowers: [ 'strength', 'kind' ], superHeroType: [ 'animal' ] },
      { text: 'falcon', superHeroPowers: [ 'kind', 'tech', 'fly' ], superHeroType: [ 'animal' ] },
      { text: 'flamingo', superHeroPowers: [ 'kind', 'fly' ], superHeroType: [ 'animal' ] },
      { text: 'honey badger', superHeroPowers: [ 'strength', 'kind' ], superHeroType: [ 'animal' ] },
      { text: 'hornet', superHeroPowers: [ 'fly', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'jaguar', superHeroPowers: [ 'strength', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'kangaroo', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal' ] },
      { text: 'lion', superHeroPowers: [ 'strength', 'kind', 'smart' ], superHeroType: [ 'animal' ] },
      { text: 'llama', superHeroPowers: [ 'kind', 'smart' ], superHeroType: [ 'animal' ] },
      { text: 'owl', superHeroPowers: [ 'smart', 'fly', 'kind' ], superHeroType: [ 'animal' ] },
      { text: 'panther', superHeroPowers: [ 'strength', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'pegasus', superHeroPowers: [ 'smart', 'fly', 'tech', 'kind' ], superHeroType: [ 'animal' ] },
      { text: 'phoenix', superHeroPowers: [ 'smart', 'fly', 'tech', 'kind' ], superHeroType: [ 'animal' ] },
      { text: 'platypus', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'porpoise', superHeroPowers: [ 'smart', 'tech', 'kind' ], superHeroType: [ 'animal' ] },
      { text: 'raven', superHeroPowers: [ 'smart', 'tech', 'fly' ], superHeroType: [ 'animal' ] },
      { text: 'rhino', superHeroPowers: [ 'strength', 'tech', 'kind' ], superHeroType: [ 'animal' ] },
      { text: 'sardine', superHeroPowers: [ 'smart', 'kind' ], superHeroType: [ 'animal' ] },
      { text: 'shark', superHeroPowers: [ 'strength', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'sloth', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'sphinx', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'stallion', superHeroPowers: [ 'strength', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'sugar bear', superHeroPowers: [ 'strength', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 't rex', superHeroPowers: [ 'strength', 'tech', 'kind' ], superHeroType: [ 'animal' ] },
      { text: 'turtle', superHeroPowers: [ 'strength', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'unicorn', superHeroPowers: [ 'fly', 'smart', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'velociraptor', superHeroPowers: [ 'smart', 'strength' ], superHeroType: [ 'animal' ] },
      { text: 'viper', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'wombat', superHeroPowers: [ 'smart', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'anomaly', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'atom', superHeroPowers: [ 'tech', 'smart' ], superHeroType: [ 'nature' ] },
      { text: 'aurora', superHeroPowers: [ 'fly', 'smart', 'kind' ], superHeroType: [ 'nature' ] },
      { text: 'bubble', superHeroPowers: [ 'fly', 'tech', 'kind' ], superHeroType: [ 'nature' ] },
      { text: 'catalyst', superHeroPowers: [ 'smart', 'tech', 'kind' ], superHeroType: [ 'nature' ] },
      { text: 'comet', superHeroPowers: [ 'strength', 'smart', 'fly' ], superHeroType: [ 'nature' ] },
      { text: 'enigma', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'hail storm', superHeroPowers: [ 'strength', 'fly', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'helix', superHeroPowers: [ 'smart' ], superHeroType: [ 'nature' ] },
      { text: 'illusion', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'little dipper', superHeroPowers: [ 'fly', 'kind' ], superHeroType: [ 'nature' ] },
      { text: 'maelstrom', superHeroPowers: [ 'strength', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'mirage', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'mountain', superHeroPowers: [ 'strength' ], superHeroType: [ 'nature' ] },
      { text: 'nexus', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'oracle', superHeroPowers: [ 'smart', 'kind' ], superHeroType: [ 'nature' ] },
      { text: 'phantom', superHeroPowers: [ 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'spectre', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'supernova', superHeroPowers: [ 'smart', 'tech', 'fly' ], superHeroType: [ 'nature' ] },
      { text: 'tempest', superHeroPowers: [ 'fly', 'strength' ], superHeroType: [ 'nature' ] },
      { text: 'tornado', superHeroPowers: [ 'strength', 'fly', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'typhoon', superHeroPowers: [ 'strength', 'fly', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'ursa major', superHeroPowers: [ 'fly', 'smart' ], superHeroType: [ 'nature' ] },
      { text: 'vortex', superHeroPowers: [ 'fly', 'strength' ], superHeroType: [ 'nature' ] },
      { text: 'wave', superHeroPowers: [ 'strength', 'smart', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'wonder', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind' ], superHeroType: [ 'nature' ] },
      { text: 'zenith', superHeroPowers: [ 'smart', 'tech', 'fly' ], superHeroType: [ 'nature' ] },
      { text: 'anvil', superHeroPowers: [ 'strength' ], superHeroType: [ 'object' ] },
      { text: 'arrow', superHeroPowers: [ 'fly' ], superHeroType: [ 'object' ] },
      { text: 'banana', superHeroPowers: [ 'tech', 'kind', 'smart' ], superHeroType: [ 'object' ] },
      { text: 'boomerang', superHeroPowers: [ 'fly', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'chisel', superHeroPowers: [ 'strength', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'computer', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'crowbar', superHeroPowers: [ 'strength' ], superHeroType: [ 'object' ] },
      { text: 'elixir', superHeroPowers: [ 'smart', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'fist', superHeroPowers: [ 'strength' ], superHeroType: [ 'object' ] },
      { text: 'hammer', superHeroPowers: [ 'strength', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'igloo', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'ironclad', superHeroPowers: [ 'strength', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'looking glass', superHeroPowers: [ 'smart' ], superHeroType: [ 'object' ] },
      { text: 'meatball', superHeroPowers: [ 'strength', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'monolith', superHeroPowers: [ 'strength', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'prism', superHeroPowers: [ 'smart', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'silhouette', superHeroPowers: [ 'smart', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'snowcone', superHeroPowers: [ 'strength', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'spork', superHeroPowers: [ 'tech', 'smart' ], superHeroType: [ 'object' ] },
      { text: 'stapler', superHeroPowers: [ 'tech' ], superHeroType: [ 'object' ] },
      { text: 'stardust', superHeroPowers: [ 'smart', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'stonehenge', superHeroPowers: [ 'strength', 'smart' ], superHeroType: [ 'object' ] },
      { text: 'tank', superHeroPowers: [ 'strength' ], superHeroType: [ 'object' ] },
      { text: 'titan', superHeroPowers: [ 'strength', 'kind' ], superHeroType: [ 'object' ] },
      { text: 'xray', superHeroPowers: [ 'tech', 'smart' ], superHeroType: [ 'object' ] }
    ];

    // List of name endings with associated tags
    this.endings = [
      { text: 'Of Love', superHeroPowers: [ 'kind' ] },
      { text: 'From The Future', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Joy', superHeroPowers: [ 'kind' ] },
      { text: 'Of Smiles', superHeroPowers: [ 'kind' ] },
      { text: 'Of Power', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Mercy', superHeroPowers: [ 'kind', 'strength' ] },
      { text: 'Of Destiny', superHeroPowers: [ 'smart', 'tech' ] },
      { text: 'Of Opportunity', superHeroPowers: [ 'smart', 'tech' ] },
      { text: 'Of Judgement', superHeroPowers: [ 'strength', 'smart' ] },
      { text: 'Of Healing', superHeroPowers: [ 'kind' ] },
      { text: 'Of Happiness', superHeroPowers: [ 'kind' ] },
      { text: 'Of Peace', superHeroPowers: [ 'kind' ] },
      { text: 'Of Hope', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Fate', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of the Seas', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Friendship', superHeroPowers: [ 'kind' ] },
      { text: 'Of Generosity', superHeroPowers: [ 'kind' ] },
      { text: 'Of kind', superHeroPowers: [ 'kind' ] },
      { text: 'Of Power', superHeroPowers: [ 'strength', 'kind' ] },
      { text: 'Of Enlightenment', superHeroPowers: [ 'smart', 'tech' ] },
      { text: 'Of Equality', superHeroPowers: [ 'smart', 'strength', 'kind' ] },
      { text: 'From Beyond', superHeroPowers: [ 'smart' ] },
      { text: 'Of Compassion', superHeroPowers: [ 'kind', 'strength' ] },
      { text: 'Of Insight', superHeroPowers: [ 'smart' ] },
      { text: 'Of Innovation', superHeroPowers: [ 'tech' ] },
      { text: 'Of The Stars', superHeroPowers: [ 'smart', 'fly' ] },
      { text: 'Of Boston', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Chicago', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Dayton', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'of Erie', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Galveston', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Greenville', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'of Honolulu', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Lexington', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Mexico City', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Minneapolis', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Montreal', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Pasadena', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Philadelphia', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Portland', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Sacremento', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Salt Lake City', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Shreveport', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Spokane', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Springfield', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of St. Louis', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
      { text: 'Of Tampa', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ] },
    ];

    this.moves = [
      { text: 'The Muscle Crunch', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Swole Strike', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Big Bop', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Flippity Flap Attack', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Shriners’ Swoop', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Soaring Shimmy', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Wonder Whirl', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Big Drop', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Elevator', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Cloud Kisser', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Big Balloon', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Brain Freeze', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Ponder Punch', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Brain Bender', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Mind Meld', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Logical Leap', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Fez Flipper', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Head Scratcher', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Brain Teaser', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Really Hard Riddle', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Double Hug', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Love Lock', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Heartfelt Hi-yah!', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Soulful Sprawl', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Empathetic Embrace', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Helping Hand', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Lazer of Love', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Pleasant Smile', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Fezzy Frenzy', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Digital Kick', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Code Hold', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Cyber Smash', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Mega Bite', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Virtual Vice Grip', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Circuit Clash', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Techno Thrash', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Silicon Smash', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Network Nuke', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Gadget Gust', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Benevolence Beam', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Serenity Strike', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Harmony Hit', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Goodwill Gust', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Friendship Flash', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Intellect Inferno', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Thought Thrust', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Wisdom Whack', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Idea Ignition', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Insight Invasion', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Stratospheric Spin', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Vortex Velocity', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Glide Gale', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Aero Assault', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Zephyr Zoom', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Titan Tackle', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Herculean Heave', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Iron Impact', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Power Pummel', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'The Granite Grip', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
    ];

    this.weapons = [
      { text: 'Biceps', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Tendons of Steel', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Tenacity', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Consistency', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Resilience', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Extra Reps', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Afterburners', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Leg Day', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Stretching Beforehand', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'A Balanced Diet', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Adequate Protein', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'High-Mindedness', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'A Pogo Stick', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Big Dreams', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Lofty Ideals', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Very Big Wings', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Bird Buddies', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Intellection', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Analysis', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Strategic Thinking', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Imagination', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Fabulous Hair', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Swoll Neurons', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'An Open Mind', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Quick Thinking', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Compassion', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Thoughtfulness', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Caring', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Tenderness', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Generosity', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Patience', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Hospitably Offering a Beverage', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Remembering Their Birthday', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Sending Thank You Notes', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Sharing Your Toys', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Bringing in Snacks to Share', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Holding the Door for the Next Person', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'C+', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Cookies', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Artificial Intelligence', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Reality Augmentation', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Cloud Computing', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Really, Really Big Data', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Automation', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'A Crew Of Robots', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Self-Cloning', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Blockchain', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Hardware', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Confusing Jargon', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Really Fast Loading Times', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Gigantic Storage Capabilities', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Invention', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Biometrics', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'A Long-Lasting Battery', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'A Touch Screen', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Wireless Capabilities', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Digital Copies', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Data Backup', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Quantum Capabilities', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Cybernetic Systems', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Bluetooth Connectivity', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Nano Nucleus', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Digital Domain', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Tech Transcendence', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Circuit Synapse', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Algorithmic Armour', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Coding', superHeroPowers: [ 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Empathy Energy', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Compassionate Charm', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Gentle Gesture', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Kindness Kernel', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Peace Pulse', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Selfless Act', superHeroPowers: [ 'kind' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Memory Matrix', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Cognitive Conduit', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Insight Infuser', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Logic Lattice', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Knowledge Knot', superHeroPowers: [ 'smart' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Sky Sovereignty', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Turbulence Tamer', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Altitude Adapter', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Aero Agility', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Celestial Circulation', superHeroPowers: [ 'fly' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Iron Will', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Unbreakable Bonds', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Herculean Haul', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Dynamic Durability', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Endurance Essence', superHeroPowers: [ 'strength' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
    ];

    this.hideouts = [
      { text: 'The Lair', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'The Well-Furnished Den', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'The Burrow', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'The Home Hive', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'The Nicely-Decorated Nest', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'The Primordial Soup', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'The Bear Den', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'The Workshop', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The MakerSpace', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The Studio', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The Industrial Environ', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The Factory', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The Production Space', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The Craftsman Style Home', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The Biosphere', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Biome', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'Environs', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Habitat', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Mountain', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Lily Pad', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Comfy Cave', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Coastal Flat', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Beachside Bungalow', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Cliffside Condo', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Rotating Rotunda', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Bouncy Castle', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Mobile Home', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The RV', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Very Large Tent', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Mobile Mansion', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Converted Van', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Completely Waterproof Tent', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Leaping Lodge', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Sprinting Sanctuary', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Racing Residence', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Dodging Den', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Hurdling Haven', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'action' ] },
      { text: 'The Tornado Tower', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Hurricane Hideout', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Tsunami Tent', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Earthquake Estate', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Volcano Villa', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'nature' ] },
      { text: 'The Vault', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The Gear Garage', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The Device Dome', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The Gadget Grove', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The Mechanism Manor', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'object' ] },
      { text: 'The Mighty Molehill', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'The Eagle Eyrie', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'The Falcon Fortress', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'The Turtle Tower', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
      { text: 'The Lion\'s Loft', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal' ] },
    ];

    this.origin = [
      { text: 'An elephant approached you in a forest on a starry night and whispered an ancient incantation into your ear', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'You bought an unusual amulet from a man selling bric-a-brac on the corner, and strange things started to happen', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'A purple bee stung you while you were napping under an oak tree in a pleasant meadow', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'A distant aunt gifted you a very old book and the more you read, the more your powers grow', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Your mother is descended from Egyptian gods and your father is a skilled surgeon from California', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Starting on the day after your thirteenth birthday, your powers started to kick in', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'A comet fell to earth and you chipped off a small fragment that you’ve been keeping in your pocket', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'You bought a teddy bear at a yard sale, and it seems to be a very special bear', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'You learned it all from a mage you originally met on a flight that had a layover in Shreveport', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'You got a letter in the mail from the Headquarters of Empathetic Rescue Operations and things haven’t been the same since', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'Your upstairs neighbor forgot their supersuit in the communal dryer and it got mixed in with your laundry', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'You had a vivid dream where a raven perched on your finger and sang “I Want to Know What Love Is”', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'A meteor shower granted you cosmic powers during a camping trip in the desert.', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'You discovered an ancient relic in your backyard that bestowed you with mystical abilities.', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'A rare celestial alignment activated your latent superpowers.', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'You were the sole survivor of a mysterious cosmic event that gave you unique abilities.', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
      { text: 'You found a mysterious crystal in a cave that resonated with your DNA, unlocking superhuman traits.', superHeroPowers: [ 'strength', 'smart', 'fly', 'kind', 'tech' ], superHeroType: [ 'animal', 'nature', 'object', 'action' ] },
    ]

    this.getSuperHeroName = this.getSuperHeroName.bind(this);
  }


  getSuperHeroName( superHeroPower, superHeroType, superHeroOption ) {
    //Filter name parts by superHeroPower and superHeroType
    const adjectiveMatches = this.adjectives.filter( adjective => adjective.superHeroPowers.includes(superHeroPower) && adjective.superHeroType.includes(superHeroType) );
    const endingMatches = this.endings.filter( ending => ending.superHeroPowers.includes(superHeroPower) );
    const nounMatches = this.nouns.filter( noun => noun.superHeroPowers.includes(superHeroPower) && noun.superHeroType.includes(superHeroType) );

    // console.log(adjectiveMatches);
    // console.log(nounMatches);
    // console.log(endingMatches);

    //Randomly choose one of each name parts
    const color = this.colors[Math.floor(Math.random() * this.colors.length)];
    const adjective = adjectiveMatches[Math.floor(Math.random() * adjectiveMatches.length)];
    const noun = nounMatches[Math.floor(Math.random() * nounMatches.length)];
    const ending = endingMatches[Math.floor(Math.random() * endingMatches.length)];

    //Combine name parts based on superHeroOption - superHeroOption randomly chosen from adjective, ending or surprise
    if( superHeroOption === 'noun') {
      // console.log( 'Name: ' + adjective.text + ' ' +  color.text + ' ' + noun.text );
      return "The " + noun.text;
    }

    if( superHeroOption === 'adjective') {
      // console.log( 'Name: ' + adjective.text + ' ' +  color.text + ' ' + noun.text );
      return "The " + adjective.text + ' ' + noun.text;
    }

    if( superHeroOption === 'ending') {
      // console.log( 'Name: ' + color.text + ' ' + noun.text + ' ' + ending.text );
      return "The " + noun.text + ' ' + ending.text;
    }

    // console.log( 'Name: ' + adjective.text + ' ' + color.text + ' ' + noun.text + ' ' + ending.text );
    return "The " + adjective.text + ' ' + noun.text + ' ' + ending.text;
  }

  getSuperHeroMove( superHeroPower, superHeroType ) {
    const moveMatches = this.moves.filter( move => move.superHeroPowers.includes(superHeroPower) && move.superHeroType.includes(superHeroType) );
    // console.log(moveMatches);

    const move = moveMatches[Math.floor(Math.random() * moveMatches.length)];
    return move;
  }

  getSuperHeroWeapon( superHeroPower, superHeroType ) {
    const weaponMatches = this.weapons.filter( weapon => weapon.superHeroPowers.includes(superHeroPower) && weapon.superHeroType.includes(superHeroType) );
    // console.log(weaponMatches);

    const weapon = weaponMatches[Math.floor(Math.random() * weaponMatches.length)];
    return weapon;
  }

  getSuperHeroHideout( superHeroPower, superHeroType ) {
    const hideoutMatches = this.hideouts.filter( hideout => hideout.superHeroPowers.includes(superHeroPower) && hideout.superHeroType.includes(superHeroType) );
    // console.log(hideoutMatches);

    const hideout = hideoutMatches[Math.floor(Math.random() * hideoutMatches.length)];
    return hideout;
  }

  getSuperHeroOrigin( superHeroPower, superHeroType ) {
    const originMatches = this.origin.filter( origin => origin.superHeroPowers.includes(superHeroPower) && origin.superHeroType.includes(superHeroType) );
    // console.log(originMatches);

    const origin = originMatches[Math.floor(Math.random() * originMatches.length)];
    return origin;
  }
}

class SuperHeroAttributes {
  constructor() {
    this.superHeroPowers = [ 
      { key: 'strength', label: 'I’m super strong!', color: 'super-orange', svg: faHandFist },
      { key: 'fly', label: 'I can fly!', color: 'super-red', svg: faJetFighter },
      { key: 'smart', label: 'I have a super brain!', color: 'super-purple', svg: faBrain },
      { key: 'kind', label: 'I’m super kind!', color: 'super-green', svg: faHeart },
      { key: 'tech', label: 'I’ve got super tech!', color: 'super-teal', svg: faMicrochip },
    ];

    this.superHeroTypes = [
      { key: 'animal', label: 'Animal', color: 'super-orange', svg: faPaw },
      { key: 'object', label: 'Object', color: 'super-red', svg: faHammer },
      { key: 'nature', label: 'Natural Force', color: 'super-purple', svg: faWater },
      { key: 'action', label: 'Action', color: 'super-green', svg: faBurst },
    ];

    this.superHeroOptions = [
      { key: 'noun' },
      { key: 'adjective' },
      { key: 'ending' },
      { key: 'surprise' },
    ];

    this.getSuperHeroPower = this.getSuperHeroPower.bind(this);
    this.getSuperHeroType = this.getSuperHeroType.bind(this);
    this.getSuperHeroOption = this.getSuperHeroOption.bind(this);
  }

  getSuperHeroPower( key ) {
    return this.superHeroPowers.find( superHeroPower => ( superHeroPower.key === key ) ).key;
  }

  getSuperHeroPowerSvg( key ) {
    return this.superHeroPowers.find( superHeroPower => ( superHeroPower.key === key ) ).svg;
  }

  getSuperHeroPowerColor( key ) {
    return this.superHeroPowers.find( superHeroPower => ( superHeroPower.key === key ) ).color;
  }

  getSuperHeroType( key ) {
    if( key !== 'surprise') return this.superHeroTypes.find( superHeroType => ( superHeroType.key === key ) ).key;
    
    const superHeroTypes = this.superHeroTypes.slice(0, -1);
    return superHeroTypes[Math.floor(Math.random() * superHeroTypes.length)].key;
  }

  getSuperHeroTypeSvg( key ) {
    if( key !== 'surprise') return this.superHeroTypes.find( superHeroType => ( superHeroType.key === key ) ).svg;
    
    const superHeroTypes = this.superHeroTypes.slice(0, -1);
    return superHeroTypes[Math.floor(Math.random() * superHeroTypes.length)].svg;
  }

  getSuperHeroTypeColor( key ) {
    if( key !== 'surprise') return this.superHeroTypes.find( superHeroType => ( superHeroType.key === key ) ).color;
    
    const superHeroTypes = this.superHeroTypes.slice(0, -1);
    return superHeroTypes[Math.floor(Math.random() * superHeroTypes.length)].color;
  }

  getSuperHeroOption() {
    return this.superHeroOptions[Math.floor(Math.random() * this.superHeroOptions.length)].key;
  }
}

class SuperHeroForm extends Component {
  constructor(props) {
    super(props);

    this.superHeroAttributes = new SuperHeroAttributes();
    this.superHeroNames = new SuperHeroNames();
    this.imgRef = React.createRef();
    this.dataUrl = null;

    this.state = {
      step: 1,
      superHeroName: '',
      superHeroMove: '',
      superHeroWeapon: '',
      superHeroHideout: '',
      superHeroOrigin: '',
      superHeroImage: '',
      showModal: false,
      copyImage: false,
      superHeroPower: this.superHeroAttributes.superHeroPowers[0].key,
      superHeroPowerSvg: this.superHeroAttributes.superHeroPowers[0].svg,
      superHeroPowerColor: this.superHeroAttributes.superHeroPowers[0].color,
      superHeroType: this.superHeroAttributes.superHeroTypes[0].key,
      superHeroTypeSvg: this.superHeroAttributes.superHeroTypes[0].svg,
      superHeroTypeColor: this.superHeroAttributes.superHeroTypes[0].color,
      superHeroOption: this.superHeroAttributes.superHeroOptions[0].key,
    };

    // console.log( this.superHeroAttributes );
    // console.log( this.superHeroNames );

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.prepareModal = this.prepareModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.createImage = this.createImage.bind(this);
    this.copyImage = this.copyImage.bind(this);
    this.shareImage = this.shareImage.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return state;
  }

  componentDidUpdate() {
    // console.log('SuperHeroForm componentDidUpdate');
    if( this.state.step === 4 ) {
      this.createImage();
    }
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleClick(event) {
    const target = event.currentTarget;
    var targetStep = ('step' in target.dataset) ? parseInt(target.dataset.step) : this.state.step;

  
    if('name' in target.dataset && 'value' in target.dataset) {
      // console.log('step: ' + targetStep + ' name: ' + target.dataset.name + ', value: ' + target.dataset.value);

      this.setState({ [target.dataset.name]: target.dataset.value }, () => {
        // console.log('StateUpdated - Power: ' + this.state.superHeroPower + ', Type: ' + this.state.superHeroType);
      });
    }

    targetStep++;
    this.setState({ step: targetStep }, () => {
      if(targetStep === 4)
        this.handleSubmit();
    });
  }

  handleSubmit(event=false) {
    if(event)
      event.preventDefault();

    // console.log(this.state);
    // console.log('Form Submitted - Power: ' + this.state.superHeroPower + ', Type: ' + this.state.superHeroType);

    const superHeroPower = this.superHeroAttributes.getSuperHeroPower( this.state.superHeroPower );
    const superHeroPowerSvg = this.superHeroAttributes.getSuperHeroPowerSvg( this.state.superHeroPower );
    const superHeroPowerColor = this.superHeroAttributes.getSuperHeroPowerColor( this.state.superHeroPower );
    const superHeroType = this.superHeroAttributes.getSuperHeroType( this.state.superHeroType );
    const superHeroTypeSvg = this.superHeroAttributes.getSuperHeroTypeSvg( this.state.superHeroType );
    const superHeroTypeColor = this.superHeroAttributes.getSuperHeroTypeColor( this.state.superHeroType );
    const superHeroOption = this.superHeroAttributes.getSuperHeroOption();

    // console.log(superHeroPower);
    // console.log(superHeroType);
    // console.log(superHeroOption);

    const superHeroName = this.superHeroNames.getSuperHeroName(superHeroPower, superHeroType, superHeroOption);
    const superHeroMove = this.superHeroNames.getSuperHeroMove(superHeroPower, superHeroType);
    const superHeroWeapon = this.superHeroNames.getSuperHeroWeapon(superHeroPower, superHeroType);
    const superHeroHideout = this.superHeroNames.getSuperHeroHideout(superHeroPower, superHeroType);
    const superHeroOrigin = this.superHeroNames.getSuperHeroOrigin(superHeroPower, superHeroType);

    this.setState({ 
      superHeroName: superHeroName,  
      superHeroPowerSvg: superHeroPowerSvg, 
      superHeroPowerColor: superHeroPowerColor,
      superHeroTypeSvg: superHeroTypeSvg,
      superHeroTypeColor: superHeroTypeColor, 
      superHeroMove: superHeroMove.text, 
      superHeroWeapon: superHeroWeapon.text, 
      superHeroHideout: superHeroHideout.text,
      superHeroOrigin: superHeroOrigin.text,
    });
  }

  getBackground() {
    const bg = renderToString(<FontAwesomeIcon id="superHeroTypeSvg" icon={this.state.superHeroTypeSvg} transform="shrink-6" color="#fff"/>);
    // console.log(`data:image/svg+xml;base64,${btoa(bg)}`);
    return {
      backgroundImage: `url(data:image/svg+xml;base64,${btoa(bg)})`
    };
  }

  resetForm() {
    this.setState({ step: 1 });
  }
  
  prepareModal() {
    // console.log(this.imgRef);
    if(this.imgRef.current === null) return;

    const filterClass = (node) => {
      const excludedClasses = ['img-hidden'];
      return !excludedClasses.some((classname) => node.classList?.contains(classname));
    };

    const style = {

    }

    if(this.dataUrl === null) {
      setTimeout(() => {
        this.setState({ superHeroImage: this.dataUrl });
        this.showModal();
      }, 2000);
      return;
    }

    this.setState({ superHeroImage: this.dataUrl });
    this.showModal();
  }

  showModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  createImage() {
    // console.log(this.imgRef);
    if(this.imgRef.current === null) return;

    const filterClass = (node) => {
      const excludedClasses = ['img-hidden'];
      return !excludedClasses.some((classname) => node.classList?.contains(classname));
    };

    toPng(this.imgRef.current, { cacheBust: true, filter: filterClass })
      .then((dataUrlPre) => {
        toPng(this.imgRef.current, { cacheBust: false, filter: filterClass })
          .then((dataUrl) => {
            // console.log(dataUrl);
            this.dataUrl = dataUrl;
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      })
  }

  copyImage() {
    // console.log(this.imgRef);
    if(this.imgRef.current === null) return;

    const filterClass = (node) => {
      const excludedClasses = ['img-hidden'];
      return !excludedClasses.some((classname) => node.classList?.contains(classname));
    };

    toBlob(this.imgRef.current, { cacheBust: true, filter: filterClass })
      .then((blob) => {
        try {
          navigator.clipboard.write([
            new ClipboardItem({
              'image/png': blob
            })
          ]);

          this.setState({ copyImage: true });
        } catch( err ) {
          console.error( err );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  shareImage() {
    // console.log(this.imgRef);
    if(this.imgRef.current === null) return;

    const filterClass = (node) => {
      const excludedClasses = ['img-hidden'];
      return !excludedClasses.some((classname) => node.classList?.contains(classname));
    };

    toBlob(this.imgRef.current, { cacheBust: true, filter: filterClass })
      .then((blob) => {
        const imgData = {
          files: [
            new File([blob], 'my-superhero-name.png', {
              type: blob.type
            }),
          ],
          title: 'Be a Superhero',
          text: this.state.superHeroName,
        };
        try {
          if (!(navigator.canShare(imgData))) {
            throw new Error("Can't share data.", imgData);
          }
          navigator.share(imgData);
        } catch( err ) {
          console.error( err );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    return (
      <div className="super-hero">
        <div className="super-hero-container">
          <div className="super-hero-form">
            <form id="superHeroForm" onSubmit={this.handleSubmit}>

            { this.state.step === 1 &&
                <div className="super-hero-form-step super-hero-form-start" data-step="1">
                  <img className="super-hero-form-bg" src={startBg} alt="" />
                  <img className="super-hero-form-mobile-bg" src={startBg} alt="" />
                  <img className="super-hero-form-raster-top" src={rasterDotsTop} alt="" />
                  <img className="super-hero-form-raster-bottom" src={rasterDotsBottom} alt="" />
                  <div className="super-hero-form-heading">
                    <img className="super-hero-form-heading-img" src={startHeading} alt="Find Your Superhero Identity" />
                  </div>
                  <img className="super-hero-form-superHerosImg" src={superHerosImg} alt="" />
                  <div className="super-hero-form-btn" data-step="1" onClick={this.handleClick}>
                    Get Started
                  </div>
                </div>
              }

              { this.state.step === 2 &&
                <div className="super-hero-form-step super-hero-form-superHeroPower" data-step="2">
                  <img className="super-hero-form-raster-top" src={rasterDotsTop} alt="" />
                  <img className="super-hero-form-raster-bottom" src={rasterDotsBottom} alt="" />
                  <div className="super-hero-form-step-label"><span className="super-hero-form-step-label-line" /> 01 <span className="super-hero-form-step-label-line" /></div>
                  <div className="super-hero-form-label">
                    Select your super power
                  </div>
                  <div className="super-hero-form-cards">
                    { this.superHeroAttributes.superHeroPowers.map( superHeroPower => (
                      <div className="super-hero-form-card" key={superHeroPower.key} data-step="2" data-name="superHeroPower" data-value={superHeroPower.key} onClick={this.handleClick}>
                        <div className="super-hero-form-card-outer">
                          <div className="super-hero-form-card-inner">
                            <div className={`super-hero-form-card-svg ${superHeroPower.color}`}>
                              <FontAwesomeIcon className="super-hero-form-card-svg-hover" icon={faBurstSolid} />
                              <FontAwesomeIcon icon={superHeroPower.svg} />
                            </div>
                            <div className="super-hero-form-card-label">
                              {superHeroPower.label}
                            </div>
                          </div>
                        </div>
                      </div>) 
                    ) }
                  </div>
                </div>
              }

              { this.state.step === 3 &&
                <div className="super-hero-form-step super-hero-form-superHeroType" data-step="3">
                  <img className="super-hero-form-raster-top" src={rasterDotsTop} alt="" />
                  <img className="super-hero-form-raster-bottom" src={rasterDotsBottom} alt="" />
                  <div className="super-hero-form-step-label"><span className="super-hero-form-step-label-line" /> 02 <span className="super-hero-form-step-label-line" /></div>
                  <div className="super-hero-form-label">
                    What are you named after?
                  </div>
                  <div className="super-hero-form-cards">
                    { this.superHeroAttributes.superHeroTypes.map( superHeroType => (
                      <div className="super-hero-form-card" key={superHeroType.key} data-step="3" data-name="superHeroType" data-value={superHeroType.key} onClick={this.handleClick}>
                        <div className="super-hero-form-card-outer">
                          <div className="super-hero-form-card-inner">
                            <div className={`super-hero-form-card-svg ${superHeroType.color}`}>
                              <FontAwesomeIcon className="super-hero-form-card-svg-hover" icon={faBurstSolid} />
                              <FontAwesomeIcon icon={superHeroType.svg} />
                            </div>
                            <div className="super-hero-form-card-label">
                              {superHeroType.label}
                            </div>
                          </div>
                        </div>
                      </div>) 
                    ) }
                  </div>
                </div>
              }

              { this.state.step === 4 &&
                <div className="super-hero-form-step" data-step="4">
                  <img className="super-hero-form-bg" src={startBg} alt="" />
                  <img className="super-hero-form-mobile-bg" src={startBg} alt="" />
                  <img className="super-hero-form-raster-top" src={rasterDotsTop} alt="" />
                  <img className="super-hero-form-raster-bottom" src={rasterDotsBottom} alt="" />
                  <img className="super-hero-form-result-love-img" src={giveLoveImg} alt="Give Love" />
                  <div className="super-hero-form-result">
                    <div className="super-hero-form-result-col">
                      <div className="super-hero-form-result-content">
                        <div className="super-hero-form-result-intro">
                          Now that you've got your super name, put your protective powers into action - <a href="https://donate.lovetotherescue.org/give/478950/#!/donation/checkout?c_src=lttrgenerator" target="_blank">make a donation</a> to help kids in need.
                        </div>
                        <div className="super-hero-form-btns img-hidden">
                          <div className="super-hero-form-btn" onClick={this.resetForm}><FontAwesomeIcon icon={faRotateLeft} /> Start a new name</div>
                          <div className="super-hero-form-btn" onClick={this.prepareModal}><FontAwesomeIcon icon={faShareFromSquare} /> Share with friends</div>
                        </div>
                        <img className="super-hero-form-result-love-img mobile" src={giveLoveImg} alt="Give Love" />
                      </div>
                    </div>
                    <div className="super-hero-form-result-col">
                      <div className="super-hero-form-result-card" ref={this.imgRef}>
                        <div className={`super-hero-form-result-card-head ${this.state.superHeroType} ${this.state.superHeroTypeColor}`}>
                          <div className="super-hero-form-result-card-head-bg">
                            <FontAwesomeIcon icon={this.state.superHeroTypeSvg} />
                          </div>
                          <div className="super-hero-form-result-card-subheading">Your Superhero name is&hellip;</div>
                          { this.state.superHeroName !== '' && 
                            <div className="super-hero-form-result-card-heading">{this.state.superHeroName}</div>
                          }

                          <div className={`super-hero-form-result-card-icon ${this.state.superHeroPowerColor}`}>
                            <FontAwesomeIcon icon={this.state.superHeroPowerSvg} />
                          </div>
                        </div>
                        <div className="super-hero-form-result-card-body">
                          { this.state.superHeroMove !== '' && 
                            <div className="super-hero-form-result-card-body-attr"><label>Power Move:</label> {this.state.superHeroMove}</div>
                          }
                          { this.state.superHeroWeapon !== '' && 
                            <div className="super-hero-form-result-card-body-attr"><label>Secret Weapon:</label> {this.state.superHeroWeapon}</div>
                          }
                          { this.state.superHeroHideout !== '' && 
                            <div className="super-hero-form-result-card-body-attr"><label>Hideout:</label> {this.state.superHeroHideout}</div>
                          }

                          { this.state.superHeroOrigin !== '' && 
                            <div className="super-hero-form-result-card-body-origin"><label>Origin Story:</label> {this.state.superHeroOrigin}</div>
                          }

                          <img className="super-hero-form-result-card-body-logo" src={shcLogo} alt="Shriners Hospitals for Children - Love to the rescue" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }

              <Modal closeModal={this.showModal} show={this.state.showModal} copy={this.state.copyImage} superHeroImage={this.state.superHeroImage} copyImage={this.copyImage} shareImage={this.shareImage} />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

class App extends Component {
  render() {
    return (
      <div className="SuperHeroApp__container">
        <SuperHeroForm/>
      </div>
    );
  }
}

export default App;
