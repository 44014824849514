import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import { faCopy, faDownload } from '@fortawesome/pro-light-svg-icons'
import './Modal.scss';

class Modal extends Component {
  constructor(props) {
    console.log("constructor");
    super(props);

    this.state = {
      init: true,
      image: false,
      copied: false,
      modalClass: "super-hero-modal super-hero-modal-fade",
      modalStyle: {'style': {display: 'none'}},
      modalAria: {'aria-hidden':"true"},
      modalBackdropClass: "super-hero-modal-backdrop super-hero-modal-fade",
    };

    this.modal = document.createElement('div');

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.renderModalContent = this.renderModalContent.bind(this);
    this.showModal = this.showModal.bind(this);

    this.renderModalContent();
  }

  static getDerivedStateFromProps(props, state) {
    if( state.init ) {
      state.init = false;
    }

    return state;
  }

  componentDidMount() {
    console.log("did mount");
    this.renderModalContent();
  }

  componentDidUpdate(prevProps) {
    console.log("did mount");
    this.renderModalContent();

    if( prevProps.copy !== this.props.copy) {
      if(this.props.copy) {
        console.log('copied!');
        this.setState({ copied: true });
      }
    }

    //If props did not update then end
    if( prevProps.show === this.props.show) return null;

    if(!this.props.show) {
      this.hideModal();
      return;
    }

    this.showModal();
  }

  renderModalContent() {
    if( this.state.init ) {
      document.body.appendChild(this.modal);
      this.root = ReactDOM.createRoot(this.modal);
    }

    const canShare = (!(navigator.canShare)) ? false : true;

    this.root.render(
      <>
        <div
          className={this.state.modalClass}
          id="superHeroNameShare"
          tabIndex="-1"
          {...this.state.modalAria}
          {...this.state.modalStyle}
        >
          <div className="super-hero-modal-dialog super-hero-modal-dialog-centered">
            <div className="super-hero-modal-content">
              <div className="super-hero-modal-img">
                <img src={this.props.superHeroImage} className="social-img" />
              </div>
              <div className="super-hero-modal-body">
                <div className="super-hero-modal-instructions">
                  Copy or download your image to share on your social networks.
                </div>
                <div className="super-hero-modal-actions">
                  <div className="super-hero-modal-footer-btn-grp super-hero-modal-footer-copy">
                    <FontAwesomeIcon icon={faCopy} />{' '}
                    <a
                      className="super-hero-modal-footer-btn"
                      onClick={this.props.copyImage}
                    >
                       { this.state.copied === false &&
                        <span>Copy</span>
                       }
                       { this.state.copied === true &&
                        <span>Copied!</span>
                       }
                    </a>
                  </div>
                  <div className="super-hero-modal-footer-btn-grp super-hero-modal-footer-share">
                    <FontAwesomeIcon icon={faShareFromSquare} />{' '}
                    <a
                      className="super-hero-modal-footer-btn"
                      onClick={this.props.shareImage}
                    >
                      Share
                    </a>
                  </div>
                  <div className="super-hero-modal-footer-btn-grp super-hero-modal-footer-download">
                    <FontAwesomeIcon icon={faDownload} />{' '}
                    <a
                      className="super-hero-modal-footer-btn"
                      href={this.props.superHeroImage}
                      download="my-superhero-name.png"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
              <button
                  type="button"
                  onClick={this.props.closeModal}
                  className="super-hero-modal-btn-close"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        <div
          className={this.state.modalBackdropClass}
          {...this.state.modalStyle}
        ></div>
      </>
    );
  }

  showModal() {
    if(!this.props.show) return null;
    
    var that = this;

    that.setState({ modalStyle: {'style': {display: 'block'}}, modalAria: {'aria-modal':"true", role:"dialog"} });

    setTimeout(function() {
      that.setState({ modalBackdropClass: "super-hero-modal-backdrop super-hero-modal-fade super-hero-modal-show" });
      document.querySelector('body').classList.add('modal-open');
    }, 150);

    setTimeout(function() {
      that.setState({ modalClass: "super-hero-modal super-hero-modal-fade super-hero-modal-show" });
    }, 300);
  }

  hideModal() {
    if(this.props.show) return null;
    
    var that = this;

    that.setState({ modalClass: "super-hero-modal super-hero-modal-fade", modalAria: {'aria-hidden':"true"} });
    document.querySelector('body').classList.remove('modal-open');

    setTimeout(function() {
      that.setState({ modalBackdropClass: "super-hero-modal-backdrop super-hero-modal-fade" });
    }, 150);

    setTimeout(function() {
      that.setState({ modalStyle: {'style': {display: 'none'}} });
    }, 300);
  }

  render() {
    return null;
  }
}

export default Modal;